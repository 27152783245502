import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useCollection } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { Alert, Button, IconButton, LinearProgress } from "@mui/material";
import { Unarchive } from "@mui/icons-material";
import BackLink from "../../shared/BackLink";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";

export default function ArchivedRegistrations() {
  const { registrationsCollection, queryCollection, updateDocument } = useDb();

  const handleUnArchive = async (regId) => {
    const confirm = window.confirm(
      "Are you sure you want to un-archive this registration?"
    );
    if (confirm) {
      await updateDocument("registrations", regId, {
        archived: false,
        complete: false,
      });
    }
  };

  const newRegistrationsQuery = queryCollection(
    registrationsCollection,
    "archived",
    "==",
    true
  );

  const [snapshot, loading, error] = useCollection(newRegistrationsQuery);

  const rows =
    snapshot &&
    snapshot.docs?.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      fullName: `${doc.data().first_name} ${doc.data().surname}`,
    }));

  const columns = [
    {
      field: "msisdn",
      headerName: "MSISDN",
      width: 180,
      renderCell: (params) => {
        return (
          <Link to={`/registration-detail/${params.row.id}`}>
            <Button color="primary" variant="outlined">
              {params.row.msisdn}
            </Button>
          </Link>
        );
      },
    },
    { field: "fullName", headerName: "Full Name", width: 200 },
    {
      field: "archive",
      headerName: "Un-Archive",
      width: 80,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleUnArchive(params.row.id)}>
            <Unarchive />
          </IconButton>
        );
      },
    },
    {
      field: "note",
      headerName: "Note",
      width: 400,
    },
  ];

  if (error) {
    return (
      <div>
        <Alert severity="error">{error.message}</Alert>
      </div>
    );
  }

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <h2>Registrations Archive</h2>
      <BackLink />
      <RMFDataGrid data={rows} columns={columns} />
    </div>
  );
}
