import React, { useContext } from "react";
import { fbApp } from "../firebase";
import {
  getFirestore,
  collection,
  getDoc,
  doc,
  updateDoc,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const db = getFirestore(fbApp);
  const { currentUser } = useAuth();

  const collectionNames = {
    users: "users",
    registrations: "registrations",
    userMeta: "user_meta",
    transactions: "transactions",
    products: "products",
    ng_categories: "ng_smartcall_categories",
    registration_reports: "registration_reports",
  };

  // Collection References
  const registrationsCollection = collection(db, collectionNames.registrations);
  const usersCollection = collection(db, collectionNames.users);
  const userMetaCollection = collection(db, collectionNames.userMeta);
  const transactionsCollection = collection(db, collectionNames.transactions);
  const productsCollection = collection(db, collectionNames.products);
  const categoriesCollection = collection(db, collectionNames.ng_categories);
  const registrationReportsCollection = collection(
    db,
    collectionNames.registration_reports
  );

  function getDocRef(collectionName, documentId) {
    const docRef = doc(db, collectionName, documentId);
    return docRef;
  }

  async function getSingleDocument(collectionName, documentId) {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        id: docSnap.id,
      };
    } else {
      return null;
    }
  }

  function updateDocument(collectionName, documentId, data) {
    const docRef = doc(db, collectionName, documentId);
    return updateDoc(docRef, {
      ...data,
      updatedAt: new Date(),
      updatedBy: currentUser.uid,
    });
  }

  function queryCollection(
    collection,
    fieldName,
    operator,
    fieldValue,
    limitAmt
  ) {
    return limitAmt
      ? query(
          collection,
          where(fieldName, operator, fieldValue),
          limit(limitAmt),
          orderBy("created_time", "desc")
        )
      : query(
          collection,
          where(fieldName, operator, fieldValue)
          // orderBy("created_time", "desc")
        );
  }

  function queryProducts(catId, networkId) {
    return query(
      productsCollection,
      where("category_id", "==", catId, where("network_id", "==", networkId))
    );
  }

  const value = {
    registrationsCollection,
    getSingleDocument,
    getDocRef,
    collectionNames,
    updateDocument,
    usersCollection,
    userMetaCollection,
    transactionsCollection,
    queryCollection,
    productsCollection,
    categoriesCollection,
    queryProducts,
    registrationReportsCollection,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
