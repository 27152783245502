import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

const config = {
  // Paste your Firebase config here
  apiKey: "AIzaSyB_2oPXS90zrROavDfJZgUXg74nALknkmU",
  authDomain: "pavairtime.firebaseapp.com",
  projectId: "pavairtime",
  storageBucket: "pavairtime.appspot.com",
  messagingSenderId: "469389819153",
  appId: "1:469389819153:web:314851ad1e8c04270be0b0",
  measurementId: "G-Y4P2BFD4BC",
};

const app = initializeApp(config);
export const functions = getFunctions(app);
export const fbApp = app;
