import React, { useState } from "react";
import BackLink from "../../../shared/BackLink";
import { useDb } from "../../../../contexts/DatabaseContext";
import { useCollectionData } from "react-firebase-hooks/firestore";
import moment from "moment";
import { Alert, Button, LinearProgress, Stack } from "@mui/material";
import RMFDataGrid from "../../../shared/data-grid/RMFDataGrid";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";

export default function RegistrationsReports() {
  const { registrationReportsCollection } = useDb();

  const [value, loading, error] = useCollectionData(
    registrationReportsCollection
  );

  const rows =
    value &&
    value.map((row, index) => ({
      ...row,
      id: index,
      date: moment(row.created.toDate()).format("YYYY-MM-DD HH:mm"),
    }));

  const columns = [
    {
      field: "title",
      headerName: "File Name",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            href={params.row.url}
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            size="small"
          >
            {params.row.title}
          </Button>
        );
      },
    },
    { field: "date", headerName: "Report Date", width: 200 },
  ];

  const [busy, setBusy] = useState(false);

  const handleManualReport = async () => {
    const previousDayRegistrationsManual = httpsCallable(
      functions,
      "previousDayRegistrationsManual"
    );
    setBusy(true);
    await previousDayRegistrationsManual();
    setBusy(false);
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div>
      <h1>Registrations Reports</h1>
      <BackLink />
      <Button disabled={busy} onClick={handleManualReport}>
        Manually Create Report
      </Button>
      <Stack spacing={2}>
        {rows && <RMFDataGrid data={rows} columns={columns} />}
      </Stack>
    </div>
  );
}
