import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../shared/constants";
import { useDb } from "../../../contexts/DatabaseContext";
import { Save } from "@mui/icons-material";

export default function AddNote({ open, close, regId }) {
  const { updateDocument } = useDb();
  const [loading, setLoading] = useState(false);
  const [noteText, setNoteText] = useState("");

  const handleClose = () => {
    close();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateDocument("registrations", regId, {
        note: noteText,
      });
      setLoading(false);
      setNoteText(null);
      handleClose();
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Note</DialogTitle>
      <form onSubmit={handleSave}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            label="Note"
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            startIcon={<Save />}
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
