import { MobileFriendly, Security } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useDocument } from "react-firebase-hooks/firestore";
import RegDetailCard from "../registrations/RegDetailCard";
import { Link } from "react-router-dom";
import EditUserMeta from "./EditUserMeta";

export default function UserMetaInfo({ uid }) {
  const { getDocRef, collectionNames } = useDb();
  const [snapshot, loading, error] = useDocument(
    getDocRef(collectionNames.userMeta, uid)
  );
  const [editFieldOpen, setEditFieldOpen] = useState(false);
  const [editField, setEditField] = useState({
    field: "",
    value: "",
    isNumber: false,
  });

  console.log(snapshot?.data());

  const userMeta =
    (snapshot && {
      ...snapshot.data(),
      id: snapshot.id,
    }) ||
    {};

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const handleEdit = (field, value, isNumber) => {
    setEditField({
      field,
      value,
      isNumber,
    });
    setEditFieldOpen(true);
  };

  const closeEdit = () => {
    setEditField({
      field: "",
      value: "",
    });
    setEditFieldOpen(false);
  };

  return (
    <Card>
      <CardHeader
        title={"User Credentials"}
        avatar={
          <Avatar>
            <Security />
          </Avatar>
        }
        subheader="These data are used to interface with the Airtime System."
        action={
          <Link to={`/meta-verification/${uid}`}>
            <Button variant="contained">Verify</Button>
          </Link>
        }
      />
      <CardContent>
        <Stack spacing={1}>
          <RegDetailCard
            textTitle={userMeta.terminal_id}
            subheader={"Terminal ID"}
            icon={<MobileFriendly />}
            editing={true}
            handleEdit={handleEdit}
            fieldName={"terminal_id"}
          />
          <RegDetailCard
            textTitle={userMeta.netgen_pw}
            subheader={"NG PW"}
            icon={<MobileFriendly />}
            editing={true}
            handleEdit={handleEdit}
            fieldName={"netgen_pw"}
          />
          <RegDetailCard
            textTitle={userMeta.ng_user_id}
            subheader={"NG User Id"}
            icon={<MobileFriendly />}
            editing={true}
            handleEdit={handleEdit}
            isNumber={true}
            fieldName={"ng_user_id"}
          />
          <RegDetailCard
            textTitle={userMeta.client_id}
            subheader={"NG Client ID"}
            icon={<MobileFriendly />}
            editing={true}
            fieldName={"client_id"}
            handleEdit={handleEdit}
            isNumber={true}
          />
          <RegDetailCard
            textTitle={uid}
            subheader={"App UID"}
            icon={<MobileFriendly />}
          />
        </Stack>
      </CardContent>

      <EditUserMeta
        open={editFieldOpen}
        close={closeEdit}
        field={editField.field}
        initialValue={editField.value}
        uid={uid}
        isNumber={editField.isNumber}
      />
    </Card>
  );
}
