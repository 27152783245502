import React from "react";
import { Routes, Route } from "react-router-dom";
import Registrations from "../components/admin/registrations/Registrations";
import RegistrationDetail from "../components/admin/registrations/RegistrationDetail";
import Users from "../components/admin/users/Users";
import Products from "../components/admin/products/Products";
import UserDetail from "../components/admin/users/UserDetail";
import UserMetaVerification from "../components/admin/users/UserMetaVerification";
import CategoryProducts from "../components/admin/products/CategoryProducts";
import Reports from "../components/admin/reports/Reports";
import RegistrationsReports from "../components/admin/reports/registrations/RegistrationsReports";
import ArchivedRegistrations from "../components/admin/registrations/ArchivedRegistrations";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Registrations />} />
      <Route exact path="/registrations" element={<Registrations />} />
      <Route
        exact
        path="/registrations-archive"
        element={<ArchivedRegistrations />}
      />
      <Route exact path="/users" element={<Users />} />
      <Route exact path="/user/:userId" element={<UserDetail />} />
      <Route exact path="/products" element={<Products />} />
      <Route
        exact
        path="/product-categories/:catId/:networkId"
        element={<CategoryProducts />}
      />
      <Route
        exact
        path="/registration-detail/:id"
        element={<RegistrationDetail />}
      />
      <Route
        exact
        path="/meta-verification/:uid"
        element={<UserMetaVerification />}
      />
      <Route exact path="/reports" element={<Reports />} />
      <Route exact path="/reg-reports" element={<RegistrationsReports />} />
    </Routes>
  );
}
