import { BarChart } from "@mui/icons-material";
import { Card, CardActionArea, CardHeader, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Reports() {
  return (
    <div>
      <h1>Reports</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card>
            <Link to="/reg-reports">
              <CardActionArea>
                <CardHeader
                  title="Registrations"
                  subheader="Registration file for Pastel Import."
                  avatar={<BarChart />}
                />
              </CardActionArea>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardActionArea
              href="https://lookerstudio.google.com/s/kUkVoOZX9VQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CardHeader
                title="App Report"
                subheader="Summarized dashboard of airtime app."
                avatar={<BarChart />}
              />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
