import React, { useState } from "react";
import BackLink from "../../shared/BackLink";
import { useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
} from "@mui/material";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
import {
  Business,
  Fingerprint,
  LocalPhone,
  MobileFriendly,
  RememberMe,
  VpnKey,
} from "@mui/icons-material";
import RegDetailCard from "./RegDetailCard";
import { useNavigate } from "react-router-dom";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import ResponseDialog from "./ResponseDialog";

export default function RegistrationDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getDocRef, collectionNames, updateDocument } = useDb();
  const [snack, setSnack] = useState({
    open: false,
    message: "",
  });

  const openSnack = (message) => {
    setSnack({
      open: true,
      message,
    });
  };

  const closeSnack = () => {
    setSnack({
      open: false,
      message: "",
    });
  };

  const [snapshot, loading, error] = useDocumentOnce(
    getDocRef(collectionNames.registrations, id)
  );

  const reg =
    (snapshot && {
      ...snapshot.data(),
      id: snapshot.id,
    }) ||
    {};

  const [updateState, setUpdateState] = useState({
    loading: false,
    data: {},
  });

  const [busy, setBusy] = useState(false);

  const toggleBusy = () => {
    setBusy(!busy);
  };

  const [verified, setVerified] = useState(false);
  const [verifyData, setVerifyData] = useState();
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [keyCopied, setKeyCopied] = useState(false);
  const [ngPin, setNgPin] = useState();
  const [ngTerminal, setNgTerminal] = useState();
  const [keyBusy, setKeyBusy] = useState(false);

  const handleKeyCopy = async () => {
    if (reg.reg_key) {
      console.log(reg.reg_key);
      navigator.clipboard.writeText(JSON.stringify(reg.reg_key));
      setNgPin(reg.reg_key.ng_pin);
      setNgTerminal(reg.reg_key.terminal_id);
      openSnack("Registration Key Copied!");
      setKeyCopied(true);
    } else {
      setKeyBusy(true);
      // Generate a random 6 digit PIN
      const newPin = Math.floor(100000 + Math.random() * 900000);
      const formattedNumber = "0" + reg.msisdn.substring(2);
      setNgPin(newPin);
      setNgTerminal(reg.terminal_id.replace("-", ""));
      const regJsonKey = {
        client_code: formattedNumber,
        first_name: reg.first_name,
        surname: reg.surname,
        ng_pin: newPin.toString(),
        terminal_id: reg.terminal_id.replace("-", ""),
        company_name: reg.company_name || null,
        company_address: reg.company_address || null,
        company_reg: reg.company_reg || null,
        vat_number: reg.vat_number || null,
      };
      const jsonString = JSON.stringify(regJsonKey);
      navigator.clipboard.writeText(jsonString);
      await updateDocument(collectionNames.registrations, id, {
        reg_key: regJsonKey,
      });
      openSnack("Registration Key Copied!");
      setKeyBusy(false);
      setKeyCopied(true);
    }
  };

  const handleVerify = async () => {
    toggleBusy();
    const adminLogin = httpsCallable(functions, "netgenAdminLogin");
    const data = {
      terminalId: ngTerminal,
      ngPin: ngPin,
    };
    try {
      const result = await adminLogin(data);
      console.log(result);
      setVerifyData(result.data);
      setVerifyOpen(true);
      setBusy(false);
    } catch (err) {
      window.alert(err.message);
      toggleBusy();
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const confirm = window.confirm("Are you sure?");
    if (!confirm) return;
    setUpdateState({
      ...updateState,
      terminal_id: ngTerminal,
      netgen_pw: ngPin,
      loading: true,
    });
    try {
      await updateDocument(collectionNames.registrations, id, {
        ...updateState.data,
        terminal_id: ngTerminal,
        netgen_pw: ngPin,
        complete: true,
      });
      navigate(-1);
    } catch (err) {
      setUpdateState({
        ...updateState,
        loading: false,
      });
      window.alert(err.message);
    }
  };

  if (error) {
    return (
      <div>
        <Alert severity="info">{error.message}</Alert>
        <BackLink />
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div>
      <h2>Registration Detail</h2>
      <BackLink />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={`${reg.first_name} ${reg.surname}`}
              subheader={`${reg.msisdn}`}
            />
            <CardContent>
              <Stack spacing={1}>
                <RegDetailCard
                  textTitle={reg.terminal_id.replace("-", "")}
                  icon={<MobileFriendly />}
                  subheader={"Terminal ID"}
                  openSnack={openSnack}
                />
                <RegDetailCard
                  textTitle={reg.msisdn}
                  icon={<LocalPhone />}
                  subheader={"MSISDN"}
                  openSnack={openSnack}
                />
                <RegDetailCard
                  textTitle={reg.uid}
                  icon={<RememberMe />}
                  subheader={"App User UID"}
                  openSnack={openSnack}
                />
                {reg.id_number && (
                  <RegDetailCard
                    textTitle={reg.id_number}
                    icon={<Fingerprint />}
                    subheader={"ID Number"}
                    openSnack={openSnack}
                  />
                )}
                {reg.id_type && (
                  <RegDetailCard
                    textTitle={reg.id_type}
                    icon={<Fingerprint />}
                    subheader={"ID Type"}
                    openSnack={openSnack}
                  />
                )}

                {reg.business && (
                  <>
                    <RegDetailCard
                      textTitle={reg.company_name}
                      icon={<Business />}
                      subheader={"Company Name"}
                      openSnack={openSnack}
                    />
                    <RegDetailCard
                      textTitle={reg.company_reg}
                      icon={<Business />}
                      subheader={"Company Reg."}
                      openSnack={openSnack}
                    />
                    <RegDetailCard
                      textTitle={reg.vat_number}
                      icon={<Business />}
                      subheader={"VAT Number"}
                      openSnack={openSnack}
                    />
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="PAV Info"
              subheader="Copy the Registration Key for use in the automation software:"
              action={
                keyBusy ? (
                  <CircularProgress />
                ) : (
                  <IconButton disabled={keyCopied} onClick={handleKeyCopy}>
                    <VpnKey />
                  </IconButton>
                )
              }
            />
            <form onSubmit={handleUpdate}>
              <CardContent>{busy && <LinearProgress />}</CardContent>
              <CardActions>
                <Button
                  disabled={busy || !keyCopied}
                  onClick={handleVerify}
                  variant="contained"
                >
                  Verify Registration
                </Button>
                <Button variant="contained" type="submit" disabled={!verified}>
                  Complete Registration
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>

      <Snackbar
        open={snack.open}
        autoHideDuration={3000}
        onClose={closeSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" variant="filled">
          {snack.message}
        </Alert>
      </Snackbar>

      {verifyData && (
        <ResponseDialog
          open={verifyOpen}
          data={verifyData}
          close={() => setVerifyOpen(false)}
          setVerified={setVerified}
        />
      )}
    </div>
  );
}
