import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useCollectionData } from "react-firebase-hooks/firestore";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";
import { Alert, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function Products() {
  const { categoriesCollection } = useDb();

  const [value, loading, error] = useCollectionData(categoriesCollection);

  const networkNames = {
    1: "Vodacom",
    2: "MTN",
    3: "CellC",
    9: "Telkom",
  };

  const rows =
    value &&
    value.map((row, index) => ({
      ...row,
      id: index,
      networkName: networkNames[row.network_id],
    }));

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 200,
      renderCell: (params) => {
        return (
          <Link
            to={`/product-categories/${params.row.category_id}/${params.row.network_id}`}
          >
            <Button size="small" variant="outlined">
              {params.row.title}
            </Button>
          </Link>
        );
      },
    },
    { field: "networkName", headerName: "Network", width: 200 },
  ];

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div>
      <h1>Products</h1>
      <h2>Select Category</h2>
      {!loading && rows && <RMFDataGrid data={rows} columns={columns} />}
    </div>
  );
}
