import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import BackLink from "../../shared/BackLink";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import UserMetaMatchCard from "./UserMetaMatchCard";

export default function UserMetaVerification() {
  const { uid } = useParams();
  const [verifyComplete, setVerifyComplete] = useState(false);
  const [error, setError] = useState();
  const [verificationData, setVerificationData] = useState();

  // Call an onCall cloud function and get both the netgen and usermeta, return the result.

  const runVerificationCheck = async () => {
    if (!verifyComplete) {
      const verifyUserMeta = httpsCallable(functions, "verifyUserMeta");
      console.log("Running Check");

      try {
        const result = await verifyUserMeta({ appUserId: uid });
        setVerificationData(result.data);
      } catch (error) {
        setError(error.message || "Error Verifying user meta with NG User.");
      }
      setVerifyComplete(true);
    }
  };

  useEffect(() => {
    return runVerificationCheck();
  });

  if (error) {
    return (
      <div>
        <Alert severity="error">
          Error: Check that the Terminal ID and PIN is the same as in the NG
          Airtime System
        </Alert>
        <BackLink />
      </div>
    );
  }

  if (!verifyComplete) {
    return (
      <Card>
        <CardContent>
          <LinearProgress />
          <Typography>
            Loading ... Please wait. This may take up to 1 minute.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <div>
      <BackLink />
      {verificationData && verifyComplete && (
        <Card>
          <CardHeader
            title="Verification"
            subheader="Comparing our data to the Airtime account."
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <Alert severity="info">
                    Make sure the values below match
                  </Alert>
                  <UserMetaMatchCard
                    appText={`${verificationData.user_meta.client_id}`}
                    ngText={`${verificationData.netgen_data.ClientId}`}
                    field="ClientID"
                  />
                  <UserMetaMatchCard
                    appText={`${verificationData.user_meta.ng_user_id}`}
                    ngText={`${verificationData.netgen_data.UserId}`}
                    field="User ID"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Alert severity="info">Additional Info From Airtime</Alert>

                <Card sx={{ mt: 1 }}>
                  <CardContent>
                    {Object.entries(verificationData.netgen_data).map(
                      ([key, value]) => {
                        // Check if value is a string or number
                        if (
                          typeof value === "string" ||
                          typeof value === "number" ||
                          typeof value === "boolean"
                        ) {
                          return (
                            <div key={key}>
                              <strong>{key}:</strong>{" "}
                              {typeof value === "boolean"
                                ? value.toString()
                                : value}
                            </div>
                          );
                        }
                        // Skip rendering if value is not a string or number
                        return null;
                      }
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
