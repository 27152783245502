import { Cancel, Check } from "@mui/icons-material";
import { Avatar, Card, CardHeader } from "@mui/material";
import React from "react";

export default function UserMetaMatchCard({ appText, ngText, field }) {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              backgroundColor:
                appText.trim() === ngText.trim() ? "green" : "red",
            }}
          >
            {appText === ngText ? <Check /> : <Cancel />}
          </Avatar>
        }
        title={`App: ${appText} = NG: ${ngText}`}
        subheader={field}
      />
    </Card>
  );
}
