import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useCollection } from "react-firebase-hooks/firestore";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";
import { Alert, Button, Chip, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { CheckCircle, Warning } from "@mui/icons-material";

export default function Users() {
  const { usersCollection, queryCollection } = useDb();
  const usersQuery = queryCollection(
    usersCollection,
    "account_activated",
    "==",
    true
  );
  const [snapshot, loading, error] = useCollection(usersQuery);

  const users =
    snapshot &&
    snapshot.docs.map((user) => ({
      ...user.data(),
      id: user.id,
    }));

  console.log(users);

  const columns = [
    {
      field: "clientCode",
      headerName: "Client Phone",
      width: 180,
      renderCell: (params) => {
        return (
          <Link to={`/user/${params.row.uid}`}>
            <Button variant="outlined" size="small">
              {params.row.phone_number || params.row.email}
            </Button>
          </Link>
        );
      },
    },
    {
      field: "display_name",
      headerName: "Name",
      width: 180,
    },
    {
      field: "blocked",
      headerName: "Access",
      width: 180,
      renderCell: (params) => {
        return params.row.blocked ? (
          <Chip label="Blocked" color="error" size="small" icon={<Warning />} />
        ) : (
          <Chip
            size="small"
            icon={<CheckCircle />}
            label="OK"
            color="success"
          />
        );
      },
    },
    {
      field: "registration_complete",
      headerName: "Reg. Complete",
      width: 180,
      renderCell: (params) => {
        return params.row.registration_complete ? (
          <Chip
            size="small"
            icon={<CheckCircle />}
            label="OK"
            color="success"
          />
        ) : (
          <Chip
            size="small"
            icon={<Warning />}
            label="Incomplete"
            color="error"
          />
        );
      },
    },
  ];

  return (
    <div>
      <h1>Users</h1>
      {error && <Alert severity="error">{error.message}</Alert>}
      {loading && <LinearProgress />}
      {users && <RMFDataGrid data={users} columns={columns} />}
    </div>
  );
}
