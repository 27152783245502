import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Alert, LinearProgress } from "@mui/material";
import BackLink from "../../shared/BackLink";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";

export default function CategoryProducts() {
  const { catId, networkId } = useParams();
  const { queryProducts } = useDb();

  const productsQuery = queryProducts(catId, networkId);
  const [value, loading, error] = useCollectionData(productsQuery);

  const rows =
    value &&
    value.map((row, index) => ({
      ...row,
      id: index,
    }));

  const colums = [
    { field: "description", headerName: "Description", width: 300 },
    { field: "retail_amount", headerName: "Retail Amount", width: 200 },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="info">{error.message}</Alert>;
  }

  return (
    <div>
      <h2>Category Products</h2>
      <BackLink />
      {value && <RMFDataGrid data={rows} columns={colums} />}
    </div>
  );
}
