import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@mui/material";
import { PermIdentity, Person2 } from "@mui/icons-material";
import RegDetailCard from "../registrations/RegDetailCard";

export default function UserRegInfo({ uid }) {
  const { queryCollection, registrationsCollection } = useDb();

  const userQuery = queryCollection(registrationsCollection, "uid", "==", uid);

  const [snapshot, loading, error] = useCollectionData(userQuery);

  console.log(snapshot);

  const userReg = snapshot && snapshot.length > 0 ? { ...snapshot[0] } : {};

  console.log(userReg);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return (
      <div>
        <Alert severity="error">{error.message}</Alert>
      </div>
    );
  }

  return (
    <Card>
      <CardHeader
        title={"Registration Info"}
        avatar={
          <Avatar>
            <Person2 />
          </Avatar>
        }
      />
      <CardContent>
        {userReg && (
          <RegDetailCard
            textTitle={userReg.id_number}
            subheader={"ID/Passport Number"}
            icon={<PermIdentity />}
          />
        )}
      </CardContent>
    </Card>
  );
}
