import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { textFieldProps } from "../../shared/constants";
import { Save } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";

export default function EditUserMeta({
  open,
  close,
  uid,
  field,
  initialValue,
  isNumber,
}) {
  const { updateDocument, collectionNames } = useDb();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    close();
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      console.log(uid);
      await updateDocument(collectionNames.userMeta, uid, {
        [field]: isNumber ? parseInt(value) : value,
      });
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} on onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label={field}
          defaultValue={initialValue}
          onChange={(e) => setValue(e.target.value)}
          type={isNumber ? "number" : "text"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          disabled={loading}
          color="primary"
          startIcon={<Save />}
          variant="contained"
        >
          Confirm
        </Button>
        <Button disabled={loading} color="error" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
