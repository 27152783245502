import React from "react";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AccountBox, BarChart, ExitToApp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function AdminMenu({ classes }) {
  const { logOut } = useAuth();
  return (
    <div>
      <List>
        {/* <Link to="/">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <Home />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Home</ListItemText>
          </ListItem>
        </Link> */}
        <Link to="/registrations">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <AccountBox />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>
              Registrations
            </ListItemText>
          </ListItem>
        </Link>
        <Link to="/users">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <AccountBox />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Users</ListItemText>
          </ListItem>
        </Link>
        <Link to="/products">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <AccountBox />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Products</ListItemText>
          </ListItem>
        </Link>
        <Link to="/reports">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <BarChart />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Reports</ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider />

      <ListItem>
        <Button
          startIcon={<ExitToApp />}
          onClick={() => logOut()}
          color="primary"
          variant="contained"
        >
          Log Out
        </Button>
      </ListItem>
    </div>
  );
}
