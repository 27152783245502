import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useCollection } from "react-firebase-hooks/firestore";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";
import {
  Alert,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Archive, Edit } from "@mui/icons-material";
import AddNote from "./AddNote";
import { deleteField } from "firebase/firestore";

export default function Registrations() {
  const { registrationsCollection, queryCollection, updateDocument } = useDb();
  const [selectedRegId, setSelectedRegId] = useState();
  const [noteOpen, setNoteOpen] = useState(false);

  const handleNoteOpen = (regId) => {
    setSelectedRegId(regId);
    setNoteOpen(true);
  };

  const handleNoteClose = () => {
    setNoteOpen(false);
    setSelectedRegId(null);
  };

  const handleArchive = async (regId) => {
    const confirm = window.confirm(
      "Are you sure you want to archive this registration?"
    );
    if (confirm) {
      await updateDocument("registrations", regId, {
        archived: true,
        complete: deleteField(),
      });
    }
  };

  const newRegistrationsQuery = queryCollection(
    registrationsCollection,
    "complete",
    "==",
    false
  );

  const [snapshot, loading, error] = useCollection(newRegistrationsQuery);

  const rows =
    snapshot &&
    snapshot.docs?.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      fullName: `${doc.data().first_name} ${doc.data().surname}`,
      date: doc.data().registration_date
        ? doc.data().registration_date.toDate()
        : null,
    }));

  const columns = [
    {
      field: "msisdn",
      headerName: "MSISDN",
      width: 180,
      renderCell: (params) => {
        return (
          <Link to={`/registration-detail/${params.row.id}`}>
            <Button color="primary" variant="outlined">
              {params.row.msisdn}
            </Button>
          </Link>
        );
      },
    },
    { field: "fullName", headerName: "Full Name", width: 200 },
    { field: "date", headerName: "Submitted", width: 300 },
    {
      field: "archive",
      headerName: "Archive",
      width: 80,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleArchive(params.row.id)}>
            <Archive />
          </IconButton>
        );
      },
    },
    {
      field: "note",
      headerName: "Note",
      width: 400,
      renderCell: (params) => {
        return (
          <>
            <Typography variant="span">{params.row.note || " ... "}</Typography>
            <IconButton onClick={() => handleNoteOpen(params.row.id)}>
              <Edit />
            </IconButton>
          </>
        );
      },
    },
  ];

  if (error) {
    return (
      <div>
        <Alert severity="error">{error.message}</Alert>
      </div>
    );
  }

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <h1>Registrations</h1>
      <Stack spacing={2}>
        <Link to="/registrations-archive">
          <Button variant="outlined">View Archived Registrations</Button>
        </Link>

        <RMFDataGrid data={rows} columns={columns} />
      </Stack>

      {selectedRegId && (
        <AddNote
          open={noteOpen}
          close={handleNoteClose}
          regId={selectedRegId}
        />
      )}
    </div>
  );
}
