import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

export default function ResponseDialog({ open, close, data, setVerified }) {
  const successComponent = (
    <>
      <Alert severity="success">{data.message}</Alert>
      <Stack spacing={1}>
        <Typography>ClientCode: {data.data.ClientCode}</Typography>
        <Alert severity="info">Please ensure Client Code matches yours</Alert>
        <Typography>
          AllowDSTV DSTV: {data.data.AllowDSTV.toString()}
        </Typography>
        <Typography>
          AllowOTTPrintlessVend: {data.data.AllowOTTPrintlessVend.toString()}
        </Typography>
        <Typography>
          AllowOTTVend: {data.data.AllowOTTVend.toString()}
        </Typography>
        <br />
        <Button
          variant="contained"
          onClick={() => {
            setVerified(true);
            close();
          }}
        >
          Confirm Data
        </Button>
      </Stack>
    </>
  );
  const failedComponent = (
    <>
      <Alert severity="error">{data.message}</Alert>
      <Button variant="outlined" onClick={() => close()}>
        Close
      </Button>
    </>
  );
  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>NG API Response:</DialogTitle>
      <DialogContent>
        {data.success && successComponent}
        {!data.success && failedComponent}
      </DialogContent>
    </Dialog>
  );
}
