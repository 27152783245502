import { ContentCopy, Edit } from "@mui/icons-material";
import { ButtonGroup, Card, CardHeader, IconButton } from "@mui/material";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

export default function RegDetailCard({
  textTitle,
  subheader,
  icon,
  openSnack,
  editing,
  handleEdit,
  fieldName,
  isNumber,
}) {
  const snackMsg = `${subheader} Copied!`;
  return (
    <Card>
      <CardHeader
        title={textTitle}
        subheader={subheader}
        avatar={icon}
        action={
          <ButtonGroup>
            {editing && (
              <IconButton
                onClick={() =>
                  handleEdit(fieldName, textTitle, isNumber ? isNumber : false)
                }
              >
                <Edit />
              </IconButton>
            )}
            <CopyToClipboard
              text={textTitle}
              onCopy={() => openSnack(snackMsg)}
            >
              <IconButton>
                <ContentCopy />
              </IconButton>
            </CopyToClipboard>
          </ButtonGroup>
        }
      />
    </Card>
  );
}
