import { HistoryRounded } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Stack,
} from "@mui/material";
import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useCollection } from "react-firebase-hooks/firestore";
import moment from "moment";
import { networkIcons } from "../../shared/constants";

export default function UserTransactionsPreview({ uid }) {
  const { transactionsCollection, queryCollection } = useDb();
  const query = queryCollection(transactionsCollection, "uid", "==", uid, 10);

  const [snapshot, loading, error] = useCollection(query);

  const txs =
    snapshot &&
    snapshot.docs.map((tx) => ({
      ...tx.data(),
      id: tx.id,
    }));

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Card>
      <CardHeader
        title={"Recent Transactions"}
        subheader={"10 most recent transactions"}
        avatar={
          <Avatar>
            <HistoryRounded />
          </Avatar>
        }
      />
      <CardContent>
        <Stack spacing={1}>
          {txs &&
            txs.map((tx) => (
              <Card key={tx.id}>
                <CardHeader
                  title={`${tx.recharge_msisdn} - R${tx.retail_amount}`}
                  subheader={`${moment(
                    tx.created_time.toDate()
                  ).fromNow()} | Sale ID: ${tx.vendor_reference}`}
                  avatar={<Avatar src={networkIcons[tx.network_id]} />}
                />
              </Card>
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
